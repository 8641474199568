import React from 'react'
import { LayoutIR } from '../../../components/organisms/Layout/LayoutIR'
import { SEOIR } from '../../../components/data/SEO'
import { CEOmessage } from '../../../components/organisms/CEOmessage/CEOmessage'
import { ENGLISH_TITLE } from '../../../components/data/English'

const Page = () => {
  return (
    <LayoutIR
      heading="代表メッセージ"
      headingEnglish={ENGLISH_TITLE.CEOMessage}
      BreadcrumbData={[
        { title: '株主・投資家の皆さまへ', url: '/ir' },
        { title: '経営方針', url: '/ir/policy' },
        { title: '代表メッセージ', url: '/' },
      ]}
    >
      <CEOmessage isIRPage />
    </LayoutIR>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => (
  <SEOIR title="代表メッセージ" url="/ir/policy/management" />
)
